export const screenTypes = {
    PRELOGIN: 'PRELOGIN',
    LOGIN: 'LOGIN',
    LOGIN_USERNAME: 'LOGIN_USERNAME',
    LOGIN_TEAM: 'LOGIN_TEAM',
    LOGIN_TWEEN: 'LOGIN_TWEEN',

    QUESTION_PRE: 'QUESTION_PRE',
    QUESTION_NO_ANSWERS: 'QUESTION_NO_ANSWERS',
    QUESTION_WITH_ANSWERS: 'QUESTION_WITH_ANSWERS',

    VIDEO: 'VIDEO',

    ANSWER_OVERVIEW: 'ANSWER_OVERVIEW',
    ANSWER_CORRECT: 'ANSWER_CORRECT',
    ANSWER_GIVEN: 'ANSWER_GIVEN',
    ANSWER_WRONG: 'ANSWER_WRONG',
    PUSH_BUTTON: 'PUSH_BUTTON',

    SCOREBOARD: 'SCOREBOARD'
}
// npm run dev
var lastAnswer = -1
var soundPlayed = false;
var sndMusic = new Howl({
    src: ['sounds/question.mp3', 'sounds/question.ogg']
});
var sndYeah = new Howl({
    src: ['sounds/yeah.mp3', 'sounds/yeah.ogg']
});
var sndAhh = new Howl({
    src: ['sounds/ahh.mp3', 'sounds/ahh.ogg']
});
var sndBell = new Howl({
    src: ['sounds/bell.mp3', 'sounds/bell.ogg']
});
var sndBeep = new Howl({
    src: ['sounds/beep.mp3', 'sounds/beep.ogg']
});


// 3. This function creates an <iframe> (and YouTube player)
//    after the API code downloads.

var musicPlaying = null;
var feedbackPlaying = false;
var gameState = 0;
var allElems = ["login", "login-name", "login-again", "login-tween", "login-team", "pre-login",
    "pre-question", "question-no-answers", "question-with-answers", "answer-overview",
    "answer", "push-button", "blink-screen", "scoreboard", "show-video"];

var _self = null;
var favTeams = new Object();

export default class App {
    constructor() {
        _self = this;
        this._listeners = []
        this._registerEvents()
        this.setState({ screen: screenTypes.LOGIN })
        this.curVideoStream = "";
        this.realVideo = false;
        /*<iframe id="frmVideo" style="position:fixed;bottom:0px;width:100%;height:30%" src="" frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>*/
        
        $("#btnReLogin").click(function () {
            sndBell.play();
            soundPlayed = true;
            $("#sScoreboard").css("display", "flex");
            $("#sPlaySound").hide();
            //document.querySelector('#divMain').requestFullscreen();
        });

        this.player = null;
       
        this.player = new YT.Player('frmVideo', {
            height: '50%',
            width: '100%',
            playerVars: { 'autoplay': 1, 'controls': 0, 'disablekb' : 1, 'fs':0  },
            events: {
                'onReady': this.onPlayerReady,
                'onStateChange': this.onPlayerStateChange
            }
        });
    }

    onPlayerReady(event) {        
        $("#frmVideo").css("position", "fixed");
        $("#frmVideo").css("bottom", "0px");
        $("#frmVideo").css("width", "100%");
        //$("#frmVideo").css("height", "0%");
        try {
            _self.player.loadVideoById(_self.curVideoStream, 0);
        } catch (err) { }
    }

    onPlayerStateChange(event) {
        var overlay = $("#overlay");
        if (event.data == 1) {           
            overlay.css("height", "80%");
            overlay.css("top", "20%");
        }
        if (event.data == -1) {
            overlay.css("height", "20%");
            overlay.css("top", "80%");
        }
    }

    setVideo(streamUrl, start) {
        if (streamUrl != this.curVideoStream) {
            // Set the new videostream
            this.curVideoStream = streamUrl;
            if (streamUrl == null || streamUrl == "") {
                // Remove video
                $("#frmVideo").hide();
                _self.realVideo = false;
                _self.player.stopVideo();
            }
            else {
                //.attr({ src: _self.iconUrl + invItem.icon });
                //$("#frmVideo").attr("src", streamUrl + "?autoplay=1");
                $("#frmVideo").show();
                try {
                    _self.player.loadVideoById(streamUrl, start);
                    _self.player.unMute();
                    _self.player.setVolume(100);
                    _self.player.playVideo();
                } catch (err) { }
                _self.realVideo = true;
                
            }
        }
        if (streamUrl != null && streamUrl != "") {
            try {
                // Alway try to go live
                //_self.player.seekTo(100000, true);
            } catch (err) { }
            _self.realVideo = true;
        }
    }

    setState(state) {
        let screenSelector = ''
        let bars = true

        switch (state.screen) {
            case screenTypes.PRELOGIN:
                screenSelector = 'section.pre-login'
                bars = false
            break

            case screenTypes.LOGIN:
                screenSelector = 'section.login'
                bars = false 
                $("#btnLogin1").show();
                $("#txtLoginMessage").html(state.message);
            break

            case screenTypes.LOGIN_USERNAME:
                $("#txtGameName").html(state.title);
                $("#txtLoginMessage2").html(state.message);
                screenSelector = 'section.login-name'
                bars = false
            break

            case screenTypes.LOGIN_TEAM:
                $("#txtGameNameT").html(state.title);
                $("#txtLoginMessageT").html(state.message);
                screenSelector = 'section.login-team'
                bars = false
            break

            case screenTypes.LOGIN_TWEEN:
                screenSelector = 'section.login-tween'
                $("#catTitle").html(state.title);
                $("#catMessage").html(state.message);
                if (state.icon != "")
                    $("#catIcon").html(state.icon);
                break

            case screenTypes.VIDEO:
                screenSelector = 'section.show-video'
                break

            case screenTypes.QUESTION_PRE:
                screenSelector = 'section.pre-question'
                this.resetAnswersResult()
                this.countDownPreQuestion(state.time, state.message)
                sndBeep.play();
            break

            case screenTypes.QUESTION_NO_ANSWERS:
                screenSelector = 'section.question-no-answers';
                lastAnswer = -1;
                bars = false;
                if (state.question.imageUrl != "") {
                    $("#imgQNoAnswer").show();
                    $("#imgQNoAnswer").css("background-image", 'url(' + state.question.imageUrl + ')');
                }
                else {
                    $("#imgQNoAnswer").hide();
                }
                if (musicPlaying == null) {
                    if (_self.realVideo)
                        sndMusic.volume(0.1);
                    else
                        sndMusic.volume(0.1); // 1.0
                    musicPlaying = sndMusic.play();                    

                }
                if (state.icon != "") {
                    $(".catImg").html(state.icon);
                    //$("#catQ2").html(state.icon);
                }
                
            break

            case screenTypes.QUESTION_WITH_ANSWERS:
                
                screenSelector = 'section.question-with-answers'
                bars = false
            break

            case screenTypes.ANSWER_OVERVIEW:
                screenSelector = 'section.answer-overview'
                bars = false
            break

            case screenTypes.ANSWER_GIVEN:
                this.stopMusic();
                screenSelector = 'section.answer.given'
                this.setMessage(state.message)
                break

            case screenTypes.ANSWER_CORRECT:
                this.stopMusic();
                if (!feedbackPlaying) {
                    sndYeah.play();
                    feedbackPlaying = true;
                }
                screenSelector = 'section.answer.correct'
                this.setMessage(state.message)
            break

            case screenTypes.ANSWER_WRONG:
                this.stopMusic();
                if (!feedbackPlaying) {
                    sndAhh.play();
                    feedbackPlaying = true;
                }
                screenSelector = 'section.answer.wrong'
                this.setMessage(state.message)
            break

            case screenTypes.PUSH_BUTTON:
                screenSelector = 'section.push-button'
            break

            case screenTypes.SCOREBOARD:
                feedbackPlaying = false;
                screenSelector = 'section.scoreboard'
                gameState = state.gameState;
                $("#txtScoreboard").html(state.title);
                if (state.scoreType == 1) {
                    $(".catImg").show();
                    $(".catImg").html(state.icon);
                    $("#sScoreboard").css("background-color", "#42BADB");
                }
                else {
                    $(".catImg").hide();
                    $("#sScoreboard").css("background-color", "#F6A743");
                }                
                if (state.showNext != null && state.showNext == true) {
                    $("#btnNextQuestion").show();
                    $(".catImg").hide();
                }
                else {
                    $("#btnNextQuestion").hide();
                }

                
            break

        }



        if (state.logo != null && state.logo != "" && state.screen != screenTypes.LOGIN_TWEEN) {
            // Set logo in style
            $(".logo").css("background-image", 'url(' + state.logo + ')');
            $(".lower").css("background-image", 'url(' + state.logo + ')');
            $(".logo").css("background-color", 'white');
        }

        

        this.setBarActive(bars)

        if (state.question && state.question.current !== undefined)
            this.setCurrentQuestion(state.question.current)

        if (state.question && state.question.total !== undefined)
            this.setTotalQuestions(state.question.total)

        if (state.question && state.question.content !== undefined)
            this.setQuestionContent(state.question.content)

        if (state.question && state.question.points !== undefined)
            this.setQuestionPoints(state.question.points)

        if (state.question && state.question.timeLeft !== undefined)
            this.setTimeLeft(state.question.timeLeft)

        if (state.question && state.question.amountAnswered !== undefined)
            this.setAmountAnswered(state.question.amountAnswered)
        
        if (state.question && state.question.imageUrl !== undefined && state.question.imageUrl !== "")
            this.setImageUrl(state.question.imageUrl)
        else
            this.setImageUrl("img/transparent.png")

        if (state.pin)
            this.setPin(state.pin)

        if (state.nickname)
            this.setNickname(state.nickname)

        if (state.position)
            this.setPosition(state.position)

        if (state.answers)
            this.setAnswers(state.answers, state.correctAnswer, lastAnswer)

        if (state.answersResult)            
            setTimeout(_ => this.setAnswersResult(state.answersResult), 1000)

        if (state.scores && state.nickname)
            this.setScores(state.scores, state.nickname)

        /*document.querySelectorAll('main > section')
            .forEach(
                e => e.style.display = 'none'
            )*/

        this.hideAll(allElems);        

        var vH = '0%';
        var aH = '100%';
        $("#overlay").hide();
        $("#overlay2").hide();
        if (this.curVideoStream != null && this.curVideoStream != "") {
            if (screenSelector == "section.scoreboard") {
                vH = '50%';
                aH = '50%';      
                $("#overlay").hide();
                $("#overlay2").hide();
            }
            if (screenSelector == "section.show-video") {
                vH = '90%';
                aH = '10%';
                $("#overlay").show();
                $("#overlay2").show();
            }
            else {
                vH = '0%';
                aH = '100%';
                $("#overlay").hide();
                $("#overlay2").hide();
            }
        }

        document.querySelector(screenSelector)
            .style.display = 'flex'

        document.querySelector(screenSelector)
            .style.height = aH;

        document.querySelector("section.video")
            .style.height = vH;
        $("#frmVideo").css("height", vH);

        if (state.screen == screenTypes.SCOREBOARD && !soundPlayed) {
            // First make sure the player pushed a button that plays a sound
            $("#sScoreboard").hide();
            $("#sPlaySound").css("display", "flex");
        }
    }

    hideAll(elems) {
        for (var i = 0; i < elems.length; i++) {
            $("." + elems[i]).hide();
        }
        $(".scoreboard").css("height:0px;");
    }

    stopMusic() {
        if (musicPlaying != null) {
            try {
                if (_self.realVideo)
                    sndMusic.fade(0.1, 0, 1000, musicPlaying);
                else
                    sndMusic.fade(0.1, 0, 1000, musicPlaying);
            } catch (err) { }
            musicPlaying = null;
        }
    }

    on(event, callback) {
        this._listeners.push({ event, callback })
    }

    emit(event, ...data) {
        this._listeners
            .forEach(
                listener => listener.event === event && listener.callback(...data)
            )
    }

    setScores(scores, nickname) {
        if (!scores)
            return

        document.querySelectorAll('ul.teams')
            .forEach(
                e => e.innerHTML = ''
            )

        let sortedScores = Object.keys(scores)
            /*.sort(
                (a, b) => scores[a].score > scores[b].score ? -1 : 1
            )*/

        let position = sortedScores.indexOf(nickname)

        //let topThree = sortedScores.slice(0, 3)
        let topThree = sortedScores.slice(0, sortedScores.length)
        let personalTopThree = [] //sortedScores.slice(position - 1, position + 2)

        /*if (position == -1) {
            topThree = sortedScores.slice(0, 6)
            personalTopThree = []
        }
        else {
            if (position < 3)
                personalTopThree = []

            if (sortedScores.length <= 6) {
                topThree = sortedScores
                personalTopThree = []
            }
            else {
                if (position < 6) {
                    topThree = sortedScores.slice(0, 6)
                    personalTopThree = []
                }
            }
        }*/

        //if (position === sortedScores.length - 1)
        //    personalTopThree = sortedScores.slice(position - 2, position + 1)
        
        let createElement = key => {
            let element = document.createElement('li')
            let positionElement = document.createElement('span')
            let nameElement = document.createElement('span')
            let scoreElement = document.createElement('span')
            let posIndElement = document.createElement('span')

            nameElement.innerText = scores[key].nickname //key
            
            positionElement.innerText = scores[key].position // sortedScores.indexOf(key) + 1
            scoreElement.innerText = scores[key].score
            nameElement.style = "width:70%;margin-left:5px;"

            //if (key === nickname)
            if(scores[key].ownTeam)
                element.className = 'you'

            element.appendChild(positionElement)
            posIndElement.innerHTML = '<i class="fas fa-star"></i>'
            if (favTeams[key] != null && favTeams[key] == 1) {
                posIndElement.style = "margin-left:5px;";
            }
            else {
                posIndElement.style = "display:none;"
            }
            element.appendChild(posIndElement)
            /*if (scores[key].positionIndicator != null) {
                if (scores[key].positionIndicator > 0)
                    posIndElement.innerHTML = '<i class="fas fa-caret-up"></i>'
                if (scores[key].positionIndicator < 0)
                    posIndElement.innerHTML = '<i class="fas fa-caret-down"></i>'
                if (scores[key].positionIndicator == 0)
                    posIndElement.innerHTML = '<i class="far fa-equals"></i>'
                element.appendChild(posIndElement)
            }*/
            
            element.appendChild(nameElement)
            element.appendChild(scoreElement)

            element.onclick = function () {
                // Add / remove key from dictionary
                /*if (favTeams[key] != null && favTeams[key] == 1) {
                    favTeams[key] = 0;
                    posIndElement.style = "display:none;"
                }
                else {
                    favTeams[key] = 1;
                    posIndElement.style = "margin-left:5px;"
                }*/
               
            };
            return element
        }

        topThree
            .map(createElement)
            .forEach(
                element => document.querySelector('ul.teams.top-three')
                    .append(element)
            )
        
        personalTopThree
            .map(createElement)
            .forEach(
                element => document.querySelector('ul.teams.personal-top-three')
                    .append(element)
            )
    }

    setPosition(position = '?nd') {
        document.querySelectorAll('.position')
            .forEach(
                e => e.innerText = position
            )
    }

    setImageUrl(imageUrl) {
        document.querySelectorAll('.image')
            .forEach(                
                e => e.style.backgroundImage = "url('" + imageUrl + "')"
            )
    }

    setAmountAnswered(amountAnswered) {
        document.querySelectorAll('.amount-of-answers-received')
            .forEach(
                e => e.innerText = amountAnswered + " %"
            )
    }

    setAnswers(answers, correctAnswer, ownAnswer) {
        if (!answers)
            return

        document.querySelectorAll('.answer-options > div')
            .forEach(
                (e, index) => {
                    e.classList.remove('correct')
                    e.classList.remove('correctOwn')
                    e.innerText = answers[e.className]

                    if (correctAnswer && index % 4 == correctAnswer - 1)
                        e.classList.add('correct')

                    if (ownAnswer && ownAnswer > 0 && index % 4 == ownAnswer - 1)
                        e.classList.add('correctOwn')
                }
        )

        
    }

    setAnswersResult(answers) {
        if (!answers)
            return

        let positions = ['red', 'blue', 'yellow', 'green']
        let total = Object.keys(answers)
            .map(key => answers[key])
            .reduce((a, t) => a += t)

        document.querySelectorAll('.content > .results:not(.answer-options) > div') 
            .forEach(
                (e, index) => {
                    let position = positions[index % positions.length]

                    e.querySelector('span').innerText = answers[position] + "%"

                    let div = e.querySelector('div')
                    let percentage = Math.floor(answers[position] / total * 100)

                    let offset = percentage > 90 ? '-36px' : '24px'
                    div.style.height = `calc(${percentage}% + ${offset})`
                }
            )
    }

    resetAnswersResult() {
        let positions = ['red', 'blue', 'yellow', 'green']
        document.querySelectorAll('.content > .results:not(.answer-options) > div')
            .forEach(
                (e, index) => {
                    let position = positions[index % positions.length]

                    e.querySelector('span').innerText = ""

                    let div = e.querySelector('div')
                    //let percentage = Math.floor(answers[position] / total * 100)
                                        
                    div.style.height = '0'
                }
            )
    }

    setMessage(message) {
        if (message) {
            document.querySelectorAll('.answer-message')
                .forEach(
                    e => e.innerText = message
                )
        }
    }

    setNickname(name) {
        //document.querySelector('#player-name')
        //    .innerText = name
    }

    setPin(pin) {
        document.querySelector('#pin')
            .innerText = pin
    }

    setTimeLeft(timeLeft) {
        document.querySelector('.points')
            .innerText = timeLeft
    }

    setQuestionPoints(points = 1) {
        document.querySelector('#question-points')
            .innerText = points
    }

    setQuestionContent(content = 'Wow this is cool!') {
        document.querySelectorAll('.question-content')
            .forEach(
                e => e.innerText = content
            )
    }

    setCurrentQuestion(number) {
        document.querySelectorAll('.current-question')
            .forEach(
                e => e.innerText = number
            )
    }

    setTotalQuestions(number) {
        document.querySelectorAll('.total-questions')
            .forEach(
                e => e.innerText = number
            )
    }

    setBarActive(active) {
        let element = document.querySelector('.bars')
        let className = element.className

        className = active
            ? 'bars active'
            : 'bars'

        element.className = className
    }
    
    countDownPreQuestion(time = 5, message) {
        let element = document.querySelector('#countdown-number')
        element.innerText = time
        $("#hPreQ").html(message)
        /*let interval = setInterval(
            _ => {
                element.innerText = --time
                if (time === 0)
                    clearInterval(interval)
            },
            1000
        )*/
    }

    _registerEvents() {
        document.querySelectorAll('.next')
            .forEach(e => e.onclick = _ => {
                // Confirm if game has not started yet
                var doNext = false;
                if (gameState == 0)
                    doNext = confirm("Weet je zeker dat je de quiz wilt starten? Doe dit pas als iedereen is ingelogd.");
                else
                    doNext = true;
                if (doNext) {
                    $("#btnNextQuestion").hide();
                    this.emit('questionNext')
                }
            })

        document.querySelectorAll('.skip')
            .forEach(e => e.onclick = _ => this.emit('skip'))

        document.querySelector('section.login > button')
            .onclick = event => {
                if (!soundPlayed) {
                    sndBell.play();
                    soundPlayed = true;
                }
                //document.querySelector('#divMain').requestFullscreen();
                this.emit('login', $('#txtCode').val());
            };

        document.querySelector('section.login-name > button')
            .onclick = _ => {
                //document.querySelector('#divMain').requestFullscreen();
                this.emit('loginName', $('#txtNickname').val())
            }
        document.querySelector('section.login-team > button')
            .onclick = _ => {
                //document.querySelector('#divMain').requestFullscreen();
                this.emit('loginTeam', $('#txtTeamNr').val())
            }

        document.querySelector('div.button')
            .onclick = _ => this.emit('pushButton')

        document.querySelectorAll('.answer-options.doanswer > div')
            .forEach(
                e => e.onclick = event => {
                    // Only react when on question screen
                let answer = event.target.className
                lastAnswer = -1
                if (answer == 'red')
                    lastAnswer = 1
                if (answer == 'blue')
                    lastAnswer = 2
                if (answer == 'yellow')
                    lastAnswer = 3
                if (answer == 'green')
                    lastAnswer = 4
                this.emit('answer', event.target.className)
            }
            )

    }
}