//import attach from 'fastclick'
import App from './js/App'


//attach(document.body)

function onSidebarClick(event) {
    if (event.target.className === 'toggle-bars')
        return toggleBars()

    let className = event.target.className.replace(/\ /g, '.')

    let element = document.querySelector(`section.${className}`)

    if (!element)
        return

    if (event.target.className === 'blink-screen') {
        // let screen dissapear after x seconds
        setTimeout(function () {
            element.style.display = 'none';
        }, 10000)
    }
    else {
        document.querySelectorAll('section')
            .forEach(
                element => element.style.display = 'none'
            )
    }
    

    element.style.display = 'flex'
}

function init() {
    console.log('Starting webpage')
    
    window.app = new App()

    require('./js/code')

    document.querySelectorAll('.sidebar button')
        .forEach(
            button => {
                button.onclick = onSidebarClick
            }
        )
}

window.onload = init