import App, { screenTypes } from './App'
import { setInterval, setTimeout } from 'timers'

const {
    PRELOGIN,
    LOGIN,
    LOGIN_USERNAME,
    LOGIN_TEAM,
    LOGIN_TWEEN,

    VIDEO,    

    QUESTION_PRE,
    QUESTION_WITH_ANSWERS,
    QUESTION_NO_ANSWERS,

    ANSWER_OVERVIEW,
    ANSWER_GIVEN,
    ANSWER_CORRECT,
    ANSWER_WRONG,
    PUSH_BUTTON,

    SCOREBOARD
} = screenTypes

if (!window.app) {
    window.app = new App()    
}

var game = $.connection.gameHub
var _data = null
var _connected = false
var isObserver = false
var isViewer = false
var obsUserId = null
var obsGameId = null
var locUserId = null
var obsViewId = null
$.connection.hub.url = 'https://www.qwizzy.nl/admin/signalr'; // 'http://localhost:59462/signalr';

//localStorage.removeItem("userId")

var urlParams = new URLSearchParams(location.search);
if (urlParams.has('gameid')) {
    isObserver = true
    obsGameId = parseInt(urlParams.get('gameid'))
    if (urlParams.has('userid')) {
        obsUserId = urlParams.get('userid')
    }
    else if (urlParams.has('viewid')) {
        isViewer = true
        obsViewId = urlParams.get('viewid')
    }
    else {
        // Not good, don't login
        isObserver = false
    }
}
if (urlParams.has('userid')) {    
    locUserId = urlParams.get('userid')
}
if (urlParams.has('logo')) {
    var logo = urlParams.get('logo')
    $(".logo").css("background-image", "url('/img/" + logo + ".png')")
}

function getUserId() {
    if (!isObserver) {
        // check querystring for userid
        if (locUserId != null)
            return locUserId
        else
            return localStorage.userId
    }
    else
    {
        if(obsUserId != null)
            return obsUserId
        else
            return localStorage.userId
    }
}

function getGameId() {
    if (!isObserver)
        return localStorage.gameid
    else
        return obsGameId
}

function connectionDone() {
    _connected = true
     if (getUserId() == null) {
         game.server.getDeviceId()
     }
     else {
         // already has a user id, don't do anything
         if (localStorage.gameid != null || obsGameId != null) {
             if (isObserver) {
                 if (isViewer) {
                     game.server.initObserver(getGameId(), obsViewId + "|" +getUserId() )
                 }
                 else {
                     game.server.initObserver(getGameId(), getUserId())
                 }
             }             
             else
                 game.server.init(getGameId(), getUserId())
         }
         else
             game.server.loginStep1('', getUserId())
     }
}

function connectionFail(err) {
    _connected = false
}

function initConnection() {
    try {
        $.connection.hub.stop()
    } catch (e) {}

    $.connection.hub.start().done(connectionDone).fail(connectionFail);    
}

function keepAlive() {
    // 'pings' the server to keep connection ids in sync
    if (_connected && getGameId() != null && getGameId() != -1) {
        game.server.ping(getGameId(), getUserId(), 'ownstring')
    }
}

function resize() {
    var vWidthi = window.innerWidth; //$(window).width();
    var vHeighti = window.innerHeight; //$(window).height();
   
    $('#divMain').css("height", vHeighti);
    $('#divMain').height(vHeighti); // + 400);    
}

$.connection.hub.disconnected(function () {
    _connected = false
    initConnection();    
})

$(window).on("resize", resize);

initConnection()
setInterval(function () {
    try {
        keepAlive()
    } catch (ex) { }
}, 4000)

setTimeout(function () {
    resize()
}, 2000)

game.client.getId = function (clientId) {
    localStorage.userId = clientId
    /*if (isObserver)
    {
        //obsUserId = clientId
    }
    else
        localStorage.userId = clientId*/
    if (isObserver)
        game.server.initObserver(getGameId(), getUserId())
    else
        game.server.loginStep1('', getUserId())
}

game.client.pingReceived = function (ownString) {
    var bla = ownString
}

game.client.showAlert = function (data) {
    $("#blinkscreen").css("display", "flex");
    setTimeout(function () {
        $("#blinkscreen").hide()
    }, 10000)
}

game.client.showScreen = function (screenType, data) {
    _data = data
    if (data.GameId != 0) {
        if(!isObserver)
            localStorage.gameid = data.GameId
        else
            obsGameId = data.GameId
    }
    if (data.VideoStream != null && data.VideoStream != "") {
        if (data.VideoStream.startsWith("https")) {
            var videoIdParts = data.VideoStream.split('/');
            var videoId = videoIdParts[videoIdParts.length - 1];
            videoId = videoId.replace("watch?v=", "");
            app.setVideo(videoId, data.TimeLeft);
        }
        else {
            app.setVideo(data.VideoStream);
        }
    }
    else {
        app.setVideo("");
    }
    if (screenType == 0) { //login
        app.setState({ screen: LOGIN, message: data.Message })
    }
    if (screenType == 1) { //name + icon
        app.setState({ screen: LOGIN_USERNAME, logo: data.CustomLogo, title: data.ScreenTitle, message: data.Message })
    }
    if (screenType == 2) {
        // Next Round screen
        app.setState({ screen: LOGIN_TWEEN,  icon: data.CustomLogo, title: data.ScreenTitle, message: data.Message })
    }
    if (screenType == 12) {
        app.setState({ screen: LOGIN_TEAM, icon: data.CustomLogo, title: data.ScreenTitle, message: data.Message })
    }
    if (screenType == 13) {
        app.setState({ screen: VIDEO, time: data.TimeLeft, icon: data.CustomLogo, title: data.ScreenTitle, message: data.Message })
    }
    if (screenType == 3) { //scoreboard
        // Create score object from datastructure
        

        var scoreObj = {}
        for (var i = 0; i < data.Teams.length; i++) {
            var teamObj = {}
            if (data.ScoreType == 1) {
                teamObj.score = data.Teams[i].CurrentCalcRoundScore
                teamObj.position = data.Teams[i].RoundPosition; 
                teamObj.positionIndicator = data.Teams[i].RoundPositionIndicator;
            }
            else {
                teamObj.score = data.Teams[i].CurrentCalcScore
                teamObj.position = data.Teams[i].Position;
                teamObj.positionIndicator = null;
            }
            teamObj.nickname = data.Teams[i].Name
            if (data.Teams[i].ConnectedTeams != null) {
                teamObj.ownTeam = data.Teams[i].ConnectedTeams.includes(getUserId());
            }
            else {
                teamObj.ownTeam = (data.Teams[i].DeviceId == getUserId());
            }
            scoreObj[data.Teams[i].DeviceId] = teamObj //data.Teams[i].CurrentScore
        }
       
        app.setState(
            {
                screen: SCOREBOARD,
                scores: scoreObj,
                nickname: getUserId(),
                logo: data.CustomLogo, 
                title: data.ScreenTitle,
                scoreType: data.ScoreType,
                icon: data.CustomLogo,
                showNext: data.ShowNextButton,
                gameState: data.GameState
            })
    }
    if (screenType == 4) { //waiting screen
        app.setState({
            screen: QUESTION_PRE,
            question: { current: 1 },
            logo: data.CustomLogo
        })
    }
    if (screenType == 5) { //next question announcement
        app.setState({
            screen: QUESTION_PRE,
            time: data.TimeLeft, 
            message: data.Message,
            question: {
                current: data.QuestionNr,
                total: data.QuestionTotalNr
            },
            logo: data.CustomLogo
        })
    }
    if (screenType == 6) { //question only
        app.setState(
            {
                screen: QUESTION_NO_ANSWERS,
                question: {
                    points: data.QuestionPoints,
                    content: data.QuestionText,                    
                    current: data.QuestionNr,
                    total: data.QuestionTotalNr,
                    imageUrl: data.MediaUrl
                },
                icon: data.CustomLogo
            }
        )
    }
    if (screenType == 7) { //question + input
        var qaState = {
            screen: QUESTION_WITH_ANSWERS,
            question: {
                points: 100,
                content: data.QuestionText,                
                current: data.QuestionNr,
                total: data.QuestionTotalNr,
                timeLeft: data.TimeLeft,
                imageUrl: data.MediaUrl,
                amountAnswered: data.NrAnswered
            },
            logo: data.CustomLogo
        }
        qaState.answers = {}
        qaState.answers.red = data.Answer1
        qaState.answers.blue = data.Answer2
        // Could be waar niet waar; check nr buttons
        qaState.answers.yellow = data.Answer3
        if (data.Answer3 == null || data.Answer3 == "") {
            $("#q3").hide();
        }
        else {
            $("#q3").show();
        }
        
        qaState.answers.green = data.Answer4;
        if (data.Answer4 == null || data.Answer4 == "") {
            $("#q4").hide();
        }
        else {
            $("#q4").show();
        }
        app.setState(qaState)
    }
    if (screenType == 8) { //question answered
        app.setState({
            screen: ANSWER_GIVEN,
            message: 'We wachten even tot anderen de vraag ook hebben beantwoord.',
            logo: data.CustomLogo
        })
    }
    if (screenType == 9) { //question result
        var qaState = {
            screen: ANSWER_OVERVIEW,
            question: {
                points: 100,
                content: data.QuestionText,
                current: 3,
                total: 14,
                imageUrl: data.MediaUrl
            },
            logo: data.CustomLogo
        }
        qaState.answers = {}
        qaState.answers.red = data.Answer1
        qaState.answers.blue = data.Answer2
        qaState.answers.yellow = data.Answer3
        qaState.answers.green = data.Answer4
                
        qaState.answersResult = {}        
        qaState.answersResult.red = data.NrAnswer1
        qaState.answersResult.blue = data.NrAnswer2
        qaState.answersResult.yellow = data.NrAnswer3
        qaState.answersResult.green = data.NrAnswer4


        qaState.correctAnswer = data.RightAnswerId

        app.setState(qaState)
    }
    if (screenType == 10) { //question answered
        app.setState({
            screen: ANSWER_CORRECT,
            message: data.Message,
            position: data.OwnPoints,
            logo: data.CustomLogo
            //message: 'We'll wait for other players to answer the question.'
        }); 
    }
    if (screenType == 11) { //question answered
        app.setState({
            screen: ANSWER_WRONG,
            message: data.Message,
            logo: data.CustomLogo
            //message: 'We'll wait for other players to answer the question.'
        })
    }
}

export default (
    function() {
        /*app.setState(
            {
                screen: QUESTION_NO_ANSWERS,                
                question: {
                    points: 100,
                    content: '',
                    current: 3,
                    total: 14
                },
                answersResult: {
                    red: 6,
                    blue: 2,
                    yellow: 4,
                    green: 0
                },
                correctAnswer: 1,
                answers: {
                    red: '99 roads',
                    blue: '42 roads',
                    yellow: '0 roads',
                    green: '13 roads'
                },
                scores: {
                    Bram: { score: ~~(Math.random() * 1000), nickname: 'Bram' },
                    Hugo: { score: ~~(Math.random() * 1000), nickname: 'Hugo' },
                    Robert: {
                        score: ~~(Math.random() * 1000), nickname: 'Robert' },
                    Thomas: {
                        score: ~~(Math.random() * 1000), nickname: 'Thomas' },
                    RobertJan: {
                        score: ~~(Math.random() * 1000), nickname: 'RobertJan' },
                    Tony: {
                        score: ~~(Math.random() * 1000), nickname: 'Tony' },
                    HarmJan: {
                        score: ~~(Math.random() * 1000), nickname: 'HarmJan' },
                    Milan: {
                        score: ~~(Math.random() * 1000), nickname: 'Milan' },
                    Niels: {
                        score: ~~(Math.random() * 1000), nickname: 'Niels' },
                    Zuli: {
                        score: ~~(Math.random() * 1000), nickname: 'Zuli' },
                    Mieke: {
                        score: ~~(Math.random() * 1000), nickname: 'Mieke' },
                    Berco: {
                        score: ~~(Math.random() * 1000), nickname: 'Berco' },
                    Luc: {
                        score: ~~(Math.random() * 1000), nickname: 'Luc' },
                    Job: {
                        score: ~~(Math.random() * 1000), nickname: 'Job' },
                    Daniel: {
                        score: ~~(Math.random() * 1000), nickname: 'Daniel'},
                    Jochem: {
                        score: ~~(Math.random() * 1000), nickname: 'Jochem' },
                },
                nickname: 'Bram',
                pin: 964448,
                message: 'Ow nooos!',
                position: '3rd'
            }
        )*/

        app.on(
            'questionNext',
            _ => { game.server.teamMasterNext(getGameId(), getUserId()) }
        )

        app.on(
            'pushButton',
            _ => console.log('GOT A PUSH')
        )

        app.on(
            'login',
            pin => {
                console.log('LOGIN')
                if (pin != "") {
                    $("#btnLogin1").hide();
                    game.server.loginStep1(pin, getUserId(), $("#txtEmail").val());
                }
            }
        )
        app.on(
            'loginTeam',
            name => {
                name = name.trim();
                if (name != null && name != "")
                    game.server.loginTeamId(_data.GameId, getUserId(), name);
            }
        )

        app.on(
            'loginName',
            name => {
                name = name.trim();
                if(name != null && name != "")
                    game.server.loginStep2(_data.GameId, getUserId(), name, '');            
            }
        )

        app.on(
            'next',
            _ => console.log('GOT A NEXT')
        )

        app.on(
            'skip',
            _ => console.log('GOT A SKIP')
        )

        app.on(
            'timeUp',
            _ => console.log('TIME IS OVER')
        )

        app.on(
            'answer',
            answer => {
                if (!isObserver) {
                    console.log('GOT AN ANSWER', answer)
                    // Send to server and change screen to answer sent
                    var answerNr = '-1'
                    if (answer == 'red')
                        answerNr = '1'
                    if (answer == 'blue')
                        answerNr = '2'
                    if (answer == 'yellow')
                        answerNr = '3'
                    if (answer == 'green')
                        answerNr = '4'
                    game.server.answerQuestion(_data.GameId, getUserId(), _data.Id, answerNr, _data.TimeLeft)
                    // Set screen to answer given
                    app.setState({
                        screen: ANSWER_GIVEN,
                        message: 'We\'ll wait for other players to answer the question.'
                    })
                }
            }
        )
    }
)()